* {
  margin: 0;
  color: white;
  font-family: Lato, sans-serif;
}

.dashboardScreen__banner {
  position: relative;
  width: 100%;
  height: auto;
}

.dashboardScreen__content {
  align-items: left;
  text-align: left;
  padding: 40px 80px 80px 80px;
  width: 50%;
}

.dashboardScreen__content h1 {
  color: white;
  font-size: 2.4rem;
  padding: 20px 0;
  font-weight: 700;
}

.dashboardScreen__back {
  display: flex;
  padding-bottom: 20px;
  opacity: 0.7;
  align-items: top;
}

.dashboardScreen__back__img {
  filter: invert(1);
}

.dashboardScreen__back__msg {
  color: white;
  font-size: 1rem;
}

.dashboardScreen__banner__content {
  padding:40px 0;
}

.dashboardScreen__banner__content__logo img {
  max-width:250px;
}

.dashboardScreen__map {
  padding: 40px 80px 40px 80px;
  display: flex;
  flex-direction: column;
}

.dashboardScreen__map__item {
  display: flex;
  margin-bottom: 50px;
  padding-bottom: 50px;
  border-bottom-color: rgba(0,0,0,0.5);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  align-items: center;
}

.dashboardScreen__map__item__data {
  display: flex;
  flex-direction: column;
  width: 65%;
  margin-right: 40px;
}

.dashboardScreen__map__item__data__audiodetails {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
}

.dashboardScreen__map__item__data__audiodetails > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboardScreen__map__item__data__audiodetails div > p {
  padding-left: 5px;
  margin-top: 0px;
}

.dashboardScreen__map__item__data__audioplayer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; 
}

.dashboardScreen__form__copyright {
  font-size: 1rem;
  padding: 20px;
  text-align: center;
}


/* Phones and Tablet*/
@media (max-width: 600px) {
}

/* Tablet and small laptop */
@media (max-width: 799px) {
  .dashboardScreen__content {
    align-items: left;
    text-align: left;
    padding: 20px;
    width: auto;
  }

  .dashboardScreen__content h1 {
    color: white;
    font-size: 1.8rem;
    line-height: 2.4rem;
    padding: 20px 0;
  }

  .dashboardScreen__map__item {
    flex-direction: column;
  }

  .dashboardScreen__map__item__data {
    display: flex;
    flex-direction: column;
    width: auto;
    margin-right: 0px;
  }

  .dashboardScreen__map__item__data__audiodetails {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-top: 10px;
    font-size: 14px;
  }

  .dashboardScreen__map__item__data__audiodetails > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .dashboardScreen__map__item__data__audiodetails div > p {
    padding-left: 0px;
    margin-top: 5px;
  }
  
  .dashboardScreen__map__item__data__audioplayer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%; 
    margin-top: 20px;
  }
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
